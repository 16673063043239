import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { fetchUsers, addTeamMember } from '../services/api';

export default function TeamCard({ team, onSelect, isSelected, onMemberAdded }) {
  const [showAddMember, setShowAddMember] = useState(false);
  const [newMember, setNewMember] = useState('');
  const [availableUsers, setAvailableUsers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { userRole } = useUser();

  const currentUsername = localStorage.getItem('username');
  const isAdmin = userRole === 'ADMIN';
  const isTeamManager = userRole === 'MANAGER' && team.teamLeader?.username === currentUsername;
  const isTeamMember = team.members.some(member => member.username === currentUsername);
  
  // Only admin and managers can add members
  const canAddMembers = isAdmin || (userRole === 'MANAGER' && isTeamManager);
  // User can only view teams they're a member of
  const canViewTeam = isAdmin || userRole === 'MANAGER' || isTeamMember;

  useEffect(() => {
    if (showAddMember) {
      loadAvailableUsers();
    }
  }, [showAddMember, team.members, team.teamLeader]);

  const loadAvailableUsers = async () => {
    try {
      setLoading(true);
      const users = await fetchUsers();
      const filteredUsers = users.filter(user => 
        !team.members.some(member => member.username === user.username) &&
        team.teamLeader?.username !== user.username &&
        (user.role === 'USER' || user.role === 'MANAGER')
      );
      setAvailableUsers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async (e) => {
    e.stopPropagation();
    setError('');

    if (!newMember) {
      setError('Please select a member to add');
      return;
    }

    try {
      await addTeamMember(team._id, newMember);
      setNewMember('');
      setShowAddMember(false);
      onMemberAdded();
    } catch (error) {
      console.error('Failed to add member:', error);
      setError('Failed to add team member');
    }
  };

  if (!canViewTeam) {
    return null;
  }

  return (
    <div 
      className={`p-6 transition-all transform hover:scale-105 rounded-xl shadow-lg ${
        isSelected 
          ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white' 
          : 'bg-gray-800 text-gray-100'
      }`}
      onClick={() => onSelect(team._id)}
    >
      <h3 className="mb-4 text-2xl font-bold">{team.teamName}</h3>
      
      <div className="space-y-3">
        {/* Team Leader Section */}
        <div className="flex items-center gap-2">
          <div className="p-2 bg-gray-700 rounded-full">
            <svg className="w-5 h-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </div>
          <span className="font-medium">Team Manager:</span>
          <span className="font-medium">{team.teamLeader?.username || 'Not assigned'}</span>
        </div>

        {/* Team Members Section */}
        <div className="flex items-center gap-2">
          <div className="p-2 bg-gray-700 rounded-full">
            <svg className="w-5 h-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </div>
          <span className="font-medium">Members:</span>
          <span className="font-medium">{team.members?.length || 0}</span>
        </div>

        {/* Members List */}
        {team.members?.length > 0 && (
          <div className="p-2 mt-3 bg-gray-700 rounded-lg">
            <div className="text-sm text-gray-300">
              Team Members:
              <div className="flex flex-wrap gap-1 mt-1">
                {team.members.map((member) => (
                  <span key={member._id} className="px-2 py-1 text-xs bg-gray-600 rounded">
                    {member.username}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Add Member Section - Only visible for admin and team managers */}
      {canAddMembers && (
        <div onClick={e => e.stopPropagation()} className="mt-4">
          <button 
            className="w-full px-4 py-2 text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700"
            onClick={() => setShowAddMember(!showAddMember)}
          >
            Add Member
          </button>

          {error && (
            <div className="mt-2 text-sm text-red-500">
              {error}
            </div>
          )}

          {showAddMember && (
            <div className="mt-3 space-y-2">
              <select
                value={newMember}
                onChange={(e) => setNewMember(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={loading}
              >
                <option value="">Select user</option>
                {availableUsers.map((user) => (
                  <option key={user._id} value={user.username}>
                    {user.username} ({user.role})
                  </option>
                ))}
              </select>
              <button 
                onClick={handleAddMember}
                disabled={!newMember || loading}
                className={`w-full px-4 py-2 text-white transition-colors rounded-lg ${
                  newMember && !loading
                    ? 'bg-green-600 hover:bg-green-700' 
                    : 'bg-gray-600 cursor-not-allowed'
                }`}
              >
                Add
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}