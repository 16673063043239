import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import MyWork from './pages/MyWork';
import MyReports from './pages/MyReports';
import MyTeams from './pages/MyTeams';
import Login from './pages/Login';
import Register from './pages/Register';
import MessageBoard from './pages/MessageBoard';
import Inbox from './pages/inbox';
import Feedback from './pages/feedback';
import { UserProvider, useUser } from './contexts/UserContext';

import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarSection,
  SidebarItem,
  SidebarLabel,
} from './components/sidebar';
import { SidebarLayout } from './components/sidebar-layout';
import {
  HomeIcon,
  InboxIcon,
  Square2StackIcon,
  Cog6ToothIcon,
  ChatBubbleBottomCenterIcon,
  ArrowRightStartOnRectangleIcon,
  ExclamationTriangleIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { Logo } from './components/logo';

function SidebarWithLogout() {
  const { logout, isAuthenticated } = useUser();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem('username');

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      logout();
      navigate('/login', { replace: true });
    }
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <Logo />
      </SidebarHeader>
      <SidebarBody className="flex flex-col h-full">
        <SidebarSection>
          <SidebarItem as={Link} to="/inbox">
            <InboxIcon />
            <SidebarLabel>Inbox</SidebarLabel>
          </SidebarItem>
          {isAuthenticated && (
            <SidebarItem onClick={handleLogout} className="cursor-pointer">
              <ArrowRightStartOnRectangleIcon />
              <SidebarLabel>Logout</SidebarLabel>
            </SidebarItem>
          )}
        </SidebarSection>
        <SidebarSection>
          <SidebarItem as={Link} to="/">
            <HomeIcon />
            <SidebarLabel>Home</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/my-work">
            <Square2StackIcon />
            <SidebarLabel>My Work</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/my-teams">
            <Cog6ToothIcon />
            <SidebarLabel>My Teams</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/message-board">
            <ChatBubbleBottomCenterIcon />
            <SidebarLabel>Message Board</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/feedback">
            <ExclamationTriangleIcon />
            <SidebarLabel>Support</SidebarLabel>
          </SidebarItem>
        </SidebarSection>
        <SidebarSection>
          <SidebarItem className="cursor-pointer">
            <UserIcon />
            <SidebarLabel>Hi, {isAuthenticated ? loggedInUser : 'Guest'}</SidebarLabel>
          </SidebarItem>
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
}

function AppRoutes() {
  const { isAuthenticated, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
      <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" />} />
      <Route path="/register" element={!isAuthenticated ? <Register /> : <Navigate to="/" />} />
      <Route path="/my-work" element={isAuthenticated ? <MyWork /> : <Navigate to="/login" />} />
      <Route path="/my-reports" element={isAuthenticated ? <MyReports /> : <Navigate to="/login" />} />
      <Route path="/my-teams" element={isAuthenticated ? <MyTeams /> : <Navigate to="/login" />} />
      <Route path="/message-board" element={isAuthenticated ? <MessageBoard /> : <Navigate to="/login" />} />
      <Route path="/inbox" element={isAuthenticated ? <Inbox /> : <Navigate to="/login" />} />
      <Route path="/feedback" element={isAuthenticated ? <Feedback /> : <Navigate to="/login" />} />
    </Routes>
  );
}

function App() {
  return (
    <UserProvider>
      <Router>
        <SidebarLayout sidebar={<SidebarWithLogout />}>
          <AppRoutes />
        </SidebarLayout>
      </Router>
    </UserProvider>
  );
}

export default App;