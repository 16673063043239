import React, { useState, useEffect } from 'react';
import { fetchLoggedInUserObjectives } from '../services/api';

const InboxBoard = () => {
  const [tasks, setTasks] = useState([]);
  const [slackMessages, setSlackMessages] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [notes, setNotes] = useState(() => {
    const storedNotes = localStorage.getItem('notes');
    return storedNotes ? JSON.parse(storedNotes) : [];
  });
  const [newNote, setNewNote] = useState('');
  const username = localStorage.getItem('username');

  // Updated public URL for backend API
  const backendUrl = `https://pureokr-backend.onrender.com/team/672fcc0f79cb43c54428710e/getallobjectives`;

  // Calendarific API for holidays
  const calendarificAPIKey = 'owu026IcuDmqEnXmrkt4MkuJGuz6FKkw';
  const calendarificAPIUrl = `https://calendarific.com/api/v2/holidays?api_key=${calendarificAPIKey}&country=US&year=2024`;

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const data = await fetchLoggedInUserObjectives(username);
        const allObjectives = data.flatMap(team => 
          team.objectives.map(obj => ({
            id: obj._id,
            title: obj.objective,
            dueDate: obj.deadline,
            priority: obj.priority
          }))
        );
        
        const sortedTasks = allObjectives
          .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
          .slice(0, 5);
          
        setTasks(sortedTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
    if (username) {
      loadTasks();
    }
  }, [username]);
  
  // Fetch tasks, Slack messages, and holidays
  useEffect(() => {
    // Fetch tasks (Objectives) from backend
    fetch(backendUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const formattedTasks = data.objectives.map((obj) => ({
          id: obj._id,
          title: obj.objective,
          dueDate: obj.deadline,
          priority: obj.priority,
        }));
        setTasks(formattedTasks);
      })
      .catch((error) => console.error('Error fetching tasks:', error));

    // Hardcoded Slack messages
    setSlackMessages([
      { id: 1, subject: 'System Maintenance', message: 'Scheduled maintenance on December 18th, 2024.' },
      { id: 2, subject: 'Holiday Reminder', message: 'Company holiday on Christmas!' },
    ]);

    // Fetch holidays from Calendarific
    fetch(calendarificAPIUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const currentDate = new Date();
        const upcomingHolidays = data.response.holidays
          .map((holiday) => ({
            date: new Date(holiday.date.iso),
            message: holiday.name,
          }))
          .filter((holiday) => holiday.date >= currentDate)
          .sort((a, b) => a.date - b.date)
          .slice(0, 3);

        const formattedHolidays = upcomingHolidays.map((holiday, index) => ({
          id: `${holiday.date.toISOString()}-${index}`, // Unique key
          date: holiday.date.toISOString().split('T')[0], // Format date as YYYY-MM-DD
          message: holiday.message,
        }));
        setHolidays(formattedHolidays);
      })
      .catch((error) => console.error('Error fetching holidays:', error));
  }, [backendUrl, calendarificAPIUrl]);

  // Persist notes to localStorage whenever notes change
  useEffect(() => {
    localStorage.setItem('notes', JSON.stringify(notes));
  }, [notes]);

  // Add a new note
  const addNote = () => {
    if (newNote.trim() !== '') {
      const updatedNotes = [...notes, { id: Date.now(), text: newNote }];
      setNotes(updatedNotes);
      setNewNote('');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 p-6 text-gray-100">
      <div className="max-w-4xl mx-auto space-y-8">
        <h1 className="text-3xl font-bold text-center">User Inbox</h1>

        {/* Tasks Section */}
        <section className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-gray-700 pb-2">Upcoming Tasks</h2>
          {tasks.map((task) => (
            <div key={task.id} className="p-4 bg-gray-700 rounded-md hover:bg-gray-600 transition">
              <h3 className="font-semibold text-lg">{task.title}</h3>
              <p>Due: {new Date(task.dueDate).toLocaleDateString()}</p>
              <p
                className={`text-sm font-semibold ${
                  task.priority === 'High'
                    ? 'text-red-500'
                    : task.priority === 'Medium'
                    ? 'text-yellow-500'
                    : 'text-green-500'
                }`}
              >
                Priority: {task.priority}
              </p>
            </div>
          ))}
        </section>

        {/* Notes Section */}
        <section className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-gray-700 pb-2">Personal Notes</h2>
          <div className="flex items-center space-x-3">
            <input
              type="text"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Add a note..."
              className="w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={addNote}
              className="px-4 py-2 bg-blue-600 rounded-lg text-white font-semibold hover:bg-blue-500 transition"
            >
              Add
            </button>
          </div>
          <div className="space-y-3 mt-4">
            {notes.map((note) => (
              <div key={note.id} className="p-3 bg-gray-700 rounded-md">
                {note.text}
              </div>
            ))}
          </div>
        </section>

        {/* Slack Messages Section */}
        <section className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-gray-700 pb-2">Slack Messages</h2>
          {slackMessages.map((msg) => (
            <div key={msg.id} className="p-4 bg-gray-700 rounded-md hover:bg-gray-600 transition">
              <h3 className="font-semibold">{msg.subject}</h3>
              <p>{msg.message}</p>
            </div>
          ))}
        </section>

        {/* Holiday Greetings Section */}
        <section className="bg-blue-700 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-blue-600 pb-2">Holiday Greetings</h2>
          {holidays.map((holiday) => (
            <div key={holiday.id} className="p-4 bg-blue-600 rounded-md">
              <h3 className="font-semibold">{holiday.date}</h3>
              <p>{holiday.message}</p>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default InboxBoard;
