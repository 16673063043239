import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/card";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://pureokr-backend.onrender.com'  // Production URL
    : 'http://localhost:8998';  // Development URL

const Home = () => {
  const [objectiveProgress, setObjectiveProgress] = useState([]);
  const [monthlyProgress, setMonthlyProgress] = useState([]);
  const [teamDistribution, setTeamDistribution] = useState([]);
  const [stats, setStats] = useState({
    totalObjectives: 0,
    completionRate: 0,
    inProgress: 0,
    teamsInvolved: 0
  });

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/teams`);
      const teams = response.data;
      processTeamData(teams);
    } catch (error) {
      console.error('Error fetching team data:', error);
    }
  };

  const processTeamData = (teams) => {
    let totalTeams = teams.length;
    let allObjectives = [];
    let statusCounts = { completed: 0, inProgress: 0, notStarted: 0 };
    let monthlyProgressMap = new Map();
    let totalSubtasks = 0;

    // Gather all objectives and their subtasks across teams
    teams.forEach(team => {
      if (team.okrs) {
        team.okrs.forEach(okr => {
          allObjectives.push(okr);
          
          // Count subtask statuses
          if (okr.subtasks && okr.subtasks.length > 0) {
            okr.subtasks.forEach(subtask => {
              totalSubtasks++;
              switch (subtask.progress) {
                case 'Done':
                  statusCounts.completed++;
                  break;
                case 'In progress':
                  statusCounts.inProgress++;
                  break;
                case 'Not started':
                default:
                  statusCounts.notStarted++;
                  break;
              }
            });
          }

          // Process monthly data
          const month = new Date(okr.deadline).toLocaleString('default', { month: 'short' });
          if (!monthlyProgressMap.has(month)) {
            monthlyProgressMap.set(month, { completed: 0, total: 0 });
          }
          const monthData = monthlyProgressMap.get(month);
          monthData.total++;
          
          // Calculate if objective is completed based on its subtasks
          const subtasksDone = okr.subtasks ? okr.subtasks.filter(st => st.progress === 'Done').length : 0;
          const subtasksTotal = okr.subtasks ? okr.subtasks.length : 0;
          if (subtasksTotal > 0 && subtasksDone === subtasksTotal) {
            monthData.completed++;
          }
        });
      }
    });

    // Process objectives progress for bar chart
    const objectiveProgressData = allObjectives.slice(0, 4).map(obj => {
      const total = obj.subtasks ? obj.subtasks.length : 0;
      const completed = obj.subtasks ? obj.subtasks.filter(st => st.progress === 'Done').length : 0;
      return {
        name: obj.objective.length > 20 ? obj.objective.substring(0, 20) + '...' : obj.objective,
        completed: total > 0 ? Math.round((completed / total) * 100) : 0
      };
    });

    // Process monthly progress
    const monthlyProgressData = Array.from(monthlyProgressMap).map(([month, data]) => ({
      month,
      completion: data.total > 0 ? Math.round((data.completed / data.total) * 100) : 0
    })).sort((a, b) => new Date(a.month + ' 1 2024') - new Date(b.month + ' 1 2024'));

    // Process team distribution
    const teamDistributionData = [
      { name: 'Completed', value: statusCounts.completed },
      { name: 'In Progress', value: statusCounts.inProgress },
      { name: 'Not Started', value: statusCounts.notStarted }
    ];

    // Calculate overall stats
    const completionRate = totalSubtasks > 0 
      ? Math.round((statusCounts.completed / totalSubtasks) * 100)
      : 0;

    // Update state with processed data
    setObjectiveProgress(objectiveProgressData);
    setMonthlyProgress(monthlyProgressData);
    setTeamDistribution(teamDistributionData);
    setStats({
      totalObjectives: allObjectives.length,
      completionRate,
      inProgress: statusCounts.inProgress,
      teamsInvolved: totalTeams
    });

    // Debug log
    console.log('Status Counts:', statusCounts);
    console.log('Total Subtasks:', totalSubtasks);
  };

  return (
    <div className="p-4 space-y-4">
      <h1 className="mb-6 text-2xl font-bold">OKR Dashboard</h1>
      
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Objective Progress Bar Chart */}
        <Card>
          <CardHeader>
            <CardTitle>Recent Objectives Progress</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full h-64">
              <BarChart
                width={500}
                height={250}
                data={objectiveProgress}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="completed" fill="#8884d8" name="Completion Rate %" />
              </BarChart>
            </div>
          </CardContent>
        </Card>

        {/* Monthly Trend Line Chart */}
        <Card>
          <CardHeader>
            <CardTitle>Monthly Completion Trend</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full h-64">
              <LineChart
                width={500}
                height={250}
                data={monthlyProgress}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="completion" stroke="#82ca9d" name="Completion Rate %" />
              </LineChart>
            </div>
          </CardContent>
        </Card>

        {/* Team OKR Distribution Pie Chart */}
        <Card>
          <CardHeader>
            <CardTitle>Subtasks Status Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full h-64">
              <PieChart width={500} height={250}>
                <Pie
                  data={teamDistribution}
                  cx={250}
                  cy={125}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {teamDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          </CardContent>
        </Card>

        {/* Status Statistics Cards */}
        <Card>
          <CardHeader>
            <CardTitle>Overall Statistics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-blue-100 rounded-lg">
                <p className="text-lg font-semibold">Total Objectives</p>
                <p className="text-3xl font-bold text-blue-600">{stats.totalObjectives}</p>
              </div>
              <div className="p-4 bg-green-100 rounded-lg">
                <p className="text-lg font-semibold">Completion Rate</p>
                <p className="text-3xl font-bold text-green-600">{stats.completionRate}%</p>
              </div>
              <div className="p-4 bg-yellow-100 rounded-lg">
                <p className="text-lg font-semibold">In Progress</p>
                <p className="text-3xl font-bold text-yellow-600">{stats.inProgress}</p>
              </div>
              <div className="p-4 bg-purple-100 rounded-lg">
                <p className="text-lg font-semibold">Teams Involved</p>
                <p className="text-3xl font-bold text-purple-600">{stats.teamsInvolved}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Home;