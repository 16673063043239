import React from 'react';
import { useUser } from '../contexts/UserContext';

function TaskTable({ 
    rows, 
    teamData,
    calculateProgress, 
    addNewRow, 
    addSubtask, 
    removeRow, 
    updateSubtask,
    updateMainTask, 
    removeSubtask,
    formatDateForInput,
    getOwnerUsername,
}) {
    const { userRole } = useUser();
    const currentUsername = localStorage.getItem('username');
    
    const isAdmin = userRole === 'ADMIN';
    const isTeamManager = userRole === 'MANAGER' && teamData?.teamLeader?.username === currentUsername;
    const isMember = teamData?.members?.some(member => member.username === currentUsername);
    
    // New permission rules
    const canEditTeam = isAdmin || isTeamManager || isMember;
    const canAddMembers = isAdmin || isTeamManager;
    const canCreateTeam = isAdmin;

    return (
        <div className="p-4 mt-6 bg-gray-800 rounded-lg task-table">
            <table className="w-full rounded-lg table-auto">
                <thead>
                    <tr className="text-left bg-gray-700">
                        <th className="p-3 text-white">Objective</th>
                        <th className="p-3 text-white">Owner</th>
                        <th className="p-3 text-white">Deadline</th>
                        <th className="p-3 text-white">Priority</th>
                        <th className="p-3 text-white">Progress</th>
                        {canEditTeam && <th className="p-3 text-white">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {(rows || []).map((row, index) => (
                        <React.Fragment key={row._id || index}>
                            <tr className="border-b border-gray-700">
                                <td className="p-3">
                                    <input
                                        type="text"
                                        value={row.objective || ''}
                                        onChange={(e) => updateMainTask(index, 'objective', e.target.value, { objective: e.target.value })}
                                        className="w-full p-2 text-white bg-gray-700 rounded"
                                        placeholder="Objective"
                                        disabled={!canEditTeam}
                                    />
                                </td>
                                <td className="p-3">
                                    <select
                                        value={getOwnerUsername(row.owner)}
                                        onChange={(e) => updateMainTask(index, 'owner', e.target.value, { owner: e.target.value })}
                                        className="w-full p-2 text-white bg-gray-700 rounded"
                                        disabled={!canEditTeam}
                                    >
                                        {teamData?.teamLeader && (
                                            <option value={teamData.teamLeader.username}>
                                                {teamData.teamLeader.username} (Manager)
                                            </option>
                                        )}
                                        {teamData?.members?.map((member) => (
                                            <option key={member._id} value={member.username}>
                                                {member.username}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td className="p-3">
                                    <input
                                        type="date"
                                        value={formatDateForInput(row.deadline)}
                                        onChange={(e) => updateMainTask(index, 'deadline', e.target.value, { deadline: e.target.value })}
                                        className="w-full p-2 text-white bg-gray-700 rounded"
                                        disabled={!canEditTeam}
                                    />
                                </td>
                                <td className="p-3">
                                    <select
                                        value={row.priority}
                                        onChange={(e) => updateMainTask(index, 'priority', e.target.value, { priority: e.target.value })}
                                        className="w-full p-2 text-white bg-gray-700 rounded"
                                        disabled={!canEditTeam}
                                    >
                                        <option value="High">High</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Low">Low</option>
                                    </select>
                                </td>
                                <td className="p-3">
                                    <div className="w-full h-4 bg-gray-600 rounded-full">
                                        <div
                                            className="h-4 bg-blue-600 rounded-full"
                                            style={{ width: `${calculateProgress(row.subtasks)}%` }}
                                        ></div>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-300">{calculateProgress(row.subtasks)}%</p>
                                </td>
                                {canEditTeam && (
                                    <td className="p-3">
                                        <div className="flex flex-col gap-2">
                                            <button
                                                onClick={() => removeRow(index)}
                                                className="px-3 py-1 text-white bg-red-600 rounded hover:bg-red-700"
                                            >
                                                Remove
                                            </button>
                                            <button
                                                onClick={() => addSubtask(index)}
                                                className="px-3 py-1 text-white bg-green-600 rounded hover:bg-green-700"
                                            >
                                                Add Subtask
                                            </button>
                                        </div>
                                    </td>
                                )}
                            </tr>

                            {/* Subtasks section */}
                            {(row.subtasks || []).map((subtask, subIndex) => (
                                <tr key={subtask._id || subIndex} className="bg-gray-700 border-b border-gray-600">
                                    <td className="p-3 pl-8">
                                        <input
                                            type="text"
                                            value={subtask.objective || ''}
                                            onChange={(e) => updateSubtask(index, subIndex, 'objective', e.target.value)}
                                            className="w-full p-2 text-white bg-gray-600 rounded"
                                            placeholder="Subtask"
                                            disabled={!canEditTeam}
                                        />
                                    </td>
                                    <td className="p-3">
                                        <select
                                            value={getOwnerUsername(subtask.owner)}
                                            onChange={(e) => updateSubtask(index, subIndex, 'owner', e.target.value)}
                                            className="w-full p-2 text-white bg-gray-600 rounded"
                                            disabled={!canEditTeam}
                                        >
                                            {teamData?.teamLeader && (
                                                <option value={teamData.teamLeader.username}>
                                                    {teamData.teamLeader.username} (Manager)
                                                </option>
                                            )}
                                            {teamData?.members?.map((member) => (
                                                <option key={member._id} value={member.username}>
                                                    {member.username}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="p-3">
                                        <input
                                            type="date"
                                            value={formatDateForInput(subtask.deadline)}
                                            onChange={(e) => updateSubtask(index, subIndex, 'deadline', e.target.value)}
                                            className="w-full p-2 text-white bg-gray-600 rounded"
                                            disabled={!canEditTeam}
                                        />
                                    </td>
                                    <td className="p-3">
                                        <select
                                            value={subtask.priority}
                                            onChange={(e) => updateSubtask(index, subIndex, 'priority', e.target.value)}
                                            className="w-full p-2 text-white bg-gray-600 rounded"
                                            disabled={!canEditTeam}
                                        >
                                            <option value="High">High</option>
                                            <option value="Medium">Medium</option>
                                            <option value="Low">Low</option>
                                        </select>
                                    </td>
                                    <td className="p-3">
                                        <select
                                            value={subtask.progress || 'Not started'}
                                            onChange={(e) => updateSubtask(index, subIndex, 'progress', e.target.value)}
                                            className="w-full p-2 text-white bg-gray-600 rounded"
                                            disabled={!canEditTeam}
                                        >
                                            <option value="Not started">Not started</option>
                                            <option value="In progress">In progress</option>
                                            <option value="Done">Done</option>
                                        </select>
                                    </td>
                                    {canEditTeam && (
                                        <td className="p-3">
                                            <button
                                                onClick={() => removeSubtask(index, subIndex)}
                                                className="px-3 py-1 text-white bg-red-600 rounded hover:bg-red-700"
                                            >
                                                Remove
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            
            {canEditTeam && (
                <button
                    onClick={addNewRow}
                    className="px-4 py-2 mt-4 text-white bg-blue-600 rounded hover:bg-blue-700"
                >
                    Add Row
                </button>
            )}
        </div>
    );
}

export default TaskTable;