import React, { useState, useEffect } from 'react';
import { fetchUsers, createTeam } from '../services/api';

function CreateTeamModal({ isOpen, onClose, onTeamCreate }) {
    const [teamData, setTeamData] = useState({
        teamName: '',
        teamLeader: '',
    });
    const [managers, setManagers] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch managers when modal opens
    useEffect(() => {
        const loadManagers = async () => {
            try {
                setLoading(true);
                const users = await fetchUsers();
                const managerUsers = users.filter(user => 
                    user.role === 'MANAGER' || user.role === 'ADMIN'
                );
                setManagers(managerUsers);
            } catch (error) {
                setError('Failed to load managers');
                console.error('Error loading managers:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) {
            loadManagers();
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!teamData.teamName.trim()) {
            setError('Team name is required');
            return;
        }

        if (!teamData.teamLeader) {
            setError('Team manager is required');
            return;
        }

        try {
            await createTeam(teamData);
            onTeamCreate();
            onClose();
            setTeamData({ teamName: '', teamLeader: '' });
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to create team');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md p-6 bg-gray-800 rounded-lg shadow-xl">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold text-white">Create New Team</h2>
                    <button 
                        onClick={onClose}
                        className="text-gray-400 hover:text-white"
                    >
                        ×
                    </button>
                </div>

                {error && (
                    <div className="p-3 mb-4 text-red-300 bg-red-500 rounded bg-opacity-20">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-300">
                            Team Name
                        </label>
                        <input
                            type="text"
                            value={teamData.teamName}
                            onChange={(e) => setTeamData({...teamData, teamName: e.target.value})}
                            className="w-full px-3 py-2 text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter team name"
                        />
                    </div>

                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-300">
                            Team Manager
                        </label>
                        <select
                            value={teamData.teamLeader}
                            onChange={(e) => setTeamData({...teamData, teamLeader: e.target.value})}
                            className="w-full px-3 py-2 text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            disabled={loading}
                        >
                            <option value="">Select a manager</option>
                            {managers.map((manager) => (
                                <option key={manager._id} value={manager.username}>
                                    {manager.username} ({manager.role})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex justify-end gap-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-gray-300 transition-colors bg-gray-700 rounded-lg hover:text-white hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-500"
                            disabled={loading}
                        >
                            Create Team
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateTeamModal;