import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://pureokr-backend.onrender.com'  // Production URL
    : 'http://localhost:8998';  // Development URL

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
};

// Axios interceptor for authentication
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Teams API
export const fetchTeams = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/teams`);
        return response.data;
    } catch (error) {
        console.error('Error fetching teams:', error);
        throw error;
    }
};

export const createTeam = async (teamData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/teams`, {
            teamName: teamData.teamName,
            teamLeader: teamData.teamLeader,
            members: []
        });
        return response.data;
    } catch (error) {
        console.error('Error creating team:', error);
        throw error;
    }
};

export const addTeamMember = async (teamId, username) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/teams/${teamId}/members`, { username });
        return response.data;
    } catch (error) {
        console.error('Error adding team member:', error);
        throw error;
    }
};

// Objectives API
export const fetchTeamObjectives = async (teamId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/team/${teamId}/getallobjectives`);
        return response.data;
    } catch (error) {
        console.error('Error fetching team objectives:', error);
        throw error;
    }
};

export const addTeamObjective = async (teamId, objectiveData) => {
    try {
        const formattedObjective = {
            ...objectiveData,
            deadline: formatDate(objectiveData.deadline),
            subtasks: objectiveData.subtasks?.map(subtask => ({
                ...subtask,
                deadline: formatDate(subtask.deadline)
            })) || []
        };
        const response = await axios.post(`${API_BASE_URL}/team/${teamId}/createobjective`, formattedObjective);
        return response.data;
    } catch (error) {
        console.error('Error adding objective:', error);
        throw error;
    }
};

export const updateTeamObjective = async (teamId, objectiveId, updatedData) => {
    try {
        const formattedData = {
            ...updatedData
        };
        const response = await axios.patch(
            `${API_BASE_URL}/team/${teamId}/updateobjective/${objectiveId}`, 
            formattedData
        );
        return response.data;
    } catch (error) {
        console.error('Error updating objective:', error);
        throw error;
    }
};

export const deleteTeamObjective = async (teamId, objectiveId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/team/${teamId}/deleteobjective/${objectiveId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting objective:', error);
        throw error;
    }
};

// Subtasks API
export const addTeamSubtask = async (teamId, objectiveId, subtaskData) => {
    try {
        const formattedSubtask = {
            ...subtaskData,
            deadline: formatDate(subtaskData.deadline)
        };
        const response = await axios.post(
            `${API_BASE_URL}/team/${teamId}/objectives/${objectiveId}/subtasks`, 
            formattedSubtask
        );
        return response.data;
    } catch (error) {
        console.error('Error adding subtask:', error);
        throw error;
    }
};

export const updateTeamSubtask = async (teamId, objectiveId, subtaskId, updatedData) => {
    try {
        const formattedData = {
            ...updatedData
        };
        const response = await axios.patch(
            `${API_BASE_URL}/team/${teamId}/objectives/${objectiveId}/subtasks/${subtaskId}`, 
            formattedData
        );
        return response.data;
    } catch (error) {
        console.error('Error updating subtask:', error);
        throw error;
    }
};

export const deleteTeamSubtask = async (teamId, objectiveId, subtaskId) => {
    try {
        const response = await axios.delete(
            `${API_BASE_URL}/team/${teamId}/objectives/${objectiveId}/subtasks/${subtaskId}`
        );
        return response.data;
    } catch (error) {
        console.error('Error deleting subtask:', error);
        throw error;
    }
};

// Users API
export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/users`);
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const fetchLoggedInUserObjectives = async (username) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user/${username}/objectives`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user objectives:', error);
        throw error;
    }
};

// Authentication helpers
export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

export const getUserRole = () => {
    return localStorage.getItem('userRole');
};

export const getUsername = () => {
    return localStorage.getItem('username');
};