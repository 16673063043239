import React, { useState, useEffect } from 'react';
import TasksPage from '../components/TasksPage';
import TeamCard from '../components/TeamCard';
import CreateTeamModal from '../components/CreateTeamModal';
import { fetchTeams, fetchTeamObjectives } from '../services/api';
import { useUser } from '../contexts/UserContext';

function MyTeams() {
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamObjectives, setTeamObjectives] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    
    const { userRole, username } = useUser();

    useEffect(() => {
        loadTeams();
    }, [userRole, username]);

    useEffect(() => {
        if (selectedTeam) {
            loadTeamObjectives(selectedTeam);
        }
    }, [selectedTeam]);
    
    const loadTeams = async () => {
        try {
            setError(null);
            const teamsData = await fetchTeams();
            
            let filteredTeams;
            // Admin sees all teams
            if (userRole === 'ADMIN') {
                filteredTeams = teamsData;
            } 
            // Manager sees all teams
            else if (userRole === 'MANAGER') {
                filteredTeams = teamsData;
            } 
            // User only sees teams they're a member of
            else {
                filteredTeams = teamsData.filter(team => 
                    team.members.some(member => member.username === username)
                );
            }
            
            setTeams(filteredTeams);
        } catch (error) {
            console.error('Error loading teams:', error);
            setError('Failed to load teams. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const loadTeamObjectives = async (teamId) => {
        try {
            setError(null);
            const data = await fetchTeamObjectives(teamId);
            setTeamObjectives(data);
        } catch (error) {
            console.error('Error loading team objectives:', error);
            setError('Failed to load team objectives. Please try again later.');
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-900">
                <div className="text-xl font-semibold text-gray-200">Loading teams...</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen p-8 bg-gray-900">
            <div className="mx-auto max-w-7xl">
                <div className="flex items-center justify-between mb-8">
                    <h1 className="text-3xl font-bold text-white">My Teams</h1>
                    {userRole === 'ADMIN' && (
                        <button 
                            onClick={() => setIsCreateModalOpen(true)}
                            className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700"
                        >
                            Create New Team
                        </button>
                    )}
                </div>
                
                {userRole === 'ADMIN' && (
                    <CreateTeamModal 
                        isOpen={isCreateModalOpen}
                        onClose={() => setIsCreateModalOpen(false)}
                        onTeamCreate={loadTeams}
                    />
                )}

                {error && (
                    <div className="p-4 mb-6 text-red-300 bg-red-500 rounded-lg bg-opacity-20">
                        {error}
                    </div>
                )}
                
                {teams.length === 0 ? (
                    <div className="py-8 text-center text-gray-400">
                        <p className="text-xl">No teams available</p>
                        <p className="mt-2">
                            {userRole === 'USER' 
                                ? "You haven't been added to any teams yet." 
                                : "No teams have been created yet."}
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 gap-6 mb-8 md:grid-cols-2 lg:grid-cols-3">
                            {teams.map(team => (
                                <TeamCard 
                                    key={team._id}
                                    team={team}
                                    onSelect={setSelectedTeam}
                                    isSelected={selectedTeam === team._id}
                                    onMemberAdded={loadTeams}
                                />
                            ))}
                        </div>

                        {selectedTeam && teamObjectives && (
                            <div className="p-6 mt-8 bg-gray-800 rounded-lg">
                                <TasksPage 
                                    teamId={selectedTeam}
                                    team={teams.find(t => t._id === selectedTeam)}
                                    title={teams.find(t => t._id === selectedTeam)?.teamName}
                                    initialData={teamObjectives.objectives || []}
                                    onUpdate={loadTeamObjectives}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MyTeams;